const inViewport = ($root, $callback, $observerOptions) => {
    const observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            $callback(entry.isIntersecting, entry.target);
        });
    }, $observerOptions);

    observer.observe($root);
};

export default inViewport;
